import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About I Plus Interiors',
    component: () => import('../views/AboutIPlus.vue')
  },
  {
    path: '/about/co-founders',
    name: 'About Co-Founders',
    component: () => import('../views/AboutCofounders.vue')
  },
  {
    path: '/about/exclusives',
    name: 'About exclusives',
    component: () => import('../views/AboutExclusives.vue')
  },
  {
    path: '/exclusives',
    name: 'Exclusives',
    component: () => import('../views/Exclusives.vue')
  },
  {
    path: '/about/furniture',
    name: 'About furnitures',
    component: () => import('../views/AboutFurniture.vue')
  },
  {
    path: '/furniture',
    name: 'Furnitures',
    component: () => import('../views/Furnitures.vue')
  },
  {
    path: '/about/wall-murals',
    name: 'About Wall Murals',
    component: () => import('../views/AboutWallMurals.vue')
  },
  {
    path: '/wall-murals',
    name: 'Wall Murals',
    component: () => import('../views/WallMurals.vue')
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: () => import('../views/ContactUs.vue')
  },
  {
    path: '/:pathMatch(.*)',
    component: Home
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
