<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton>
        {{name}}
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="z-20 origin-top-right absolute left-20 sm:left-0 mt-2 w-56 rounded-md shadow-lg bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div class="py-1">
            <div v-if="isAboutUs">
                <MenuItem v-slot="{ active }">
                    <router-link to="/about" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-white', 'block px-4 py-2 text-sm']">About I Plus Interiors</router-link>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                    <router-link to="/about/co-founders" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-white', 'block px-4 py-2 text-sm']">Co-Founders</router-link>
                </MenuItem>
            </div>
            <div v-else-if="isExclusives">
                <MenuItem v-slot="{ active }">
                    <router-link to="/about/exclusives" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-white', 'block px-4 py-2 text-sm']">About I Plus Exclusives</router-link>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                    <router-link to="/exclusives" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-white', 'block px-4 py-2 text-sm']">Products</router-link>
                </MenuItem>
            </div>
            <div v-else-if="isFurnitures">
                <MenuItem v-slot="{ active }">
                    <router-link to="/about/furniture" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-white', 'block px-4 py-2 text-sm']">About I Plus Furniture</router-link>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                    <router-link to="/furniture" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-white', 'block px-4 py-2 text-sm']">Products</router-link>
                </MenuItem>
            </div>
            <div v-else>
                <MenuItem v-slot="{ active }">
                    <router-link to="/about/wall-murals" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-white', 'block px-4 py-2 text-sm']">About I Plus Wall Murals</router-link>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                    <router-link to="/wall-murals" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-white', 'block px-4 py-2 text-sm']">Products</router-link>
                </MenuItem>
            </div>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

export default {
  components: {
    MenuButton,  
    MenuItem,
    MenuItems,
    Menu,
  },
  props: {
      name: String
  },
  computed: {
      isAboutUs() {
          return this.name == 'About us'
      },
      isExclusives(){
        return this.name == 'Exclusives'
      },
      isFurnitures(){
        return this.name == 'Furniture'
      }
  }
}
</script>
