<template>
    <carousel class="w-11/12" :items-to-show="1">
      <Slide class="h-7xl" v-for="image in images" :key="image">
        <div class="carousel__item" ><img :src="image" /></div>
      </Slide>

      <template #addons>
        <navigation />
        <pagination />
      </template>
    </carousel>
</template>


<script>
import { defineComponent } from 'vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

const images = [
  require('../assets/images/front-page/1.jpg'),
  require('../assets/images/front-page/2.jpg'), 
  require('../assets/images/front-page/5.jpg'), 
  require('../assets/images/front-page/6.jpg'), 
  require('../assets/images/front-page/13.jpg'), 
  require('../assets/images/front-page/14.jpg'), 
  require('../assets/images/front-page/17.jpg'), 
  require('../assets/images/front-page/18.jpg'), 
  require('../assets/images/front-page/34.jpg'), 
]

export default defineComponent({
  name: 'Basic',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  setup() {
    return {images};
  }
});
</script>

<style>

.carousel__item img{
  max-height: 700px;
}

.carousel__item {
  min-height: 300px;
  width: 100%;
  color:  var(--vc-clr-white);
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__pagination-button {
  background-color: grey;
}

.carousel__pagination-button--active {
  background-color: white;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: solid white;
  background-color: black;
}

.carousel__prev{
  padding-right: -2vh;
}
.carousel__next{
  padding-left: -5px;
}

</style>