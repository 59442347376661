<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Disclosure as="nav" class="bg-black" v-slot="{ open }">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-20">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
          <!-- Image -->
          <div class="flex-shrink-0 flex items-center">
            <router-link to="/"> <img class="block lg:hidden h-20 w-auto" src="../assets/images/logo.png" alt="IPlusInteriors" /></router-link>
            <router-link to="/"><img class="hidden lg:block h-20 w-auto" src="../assets/images/logo.png" alt="IPlusInteriors" /></router-link>
          </div>
          <!-- PC menu -->
          <div class="z-10 hidden sm:flex items-center sm:ml-6">
            <div class="flex space-x-4">
              <router-link v-for="item in navigation" :key="item.name" :to="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'px-3 py-2 rounded-md text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">
                <!-- DropDown -->
                <div v-if="item.href == '#'" class="text-base">
                  <dropdown :name="item.name"/>
                </div>
                <!-- Not Dropdown -->
                <div v-else class="text-base">
                  {{ item.name }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile menu -->
    <DisclosurePanel class="sm:hidden">
      <div class="z-10 px-2 pt-2 pb-3 space-y-1">
        <router-link v-for="item in navigation" :key="item.name" as="a" :to="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block px-3 py-2 rounded-md text-base font-medium']" :aria-current="item.current ? 'page' : undefined">
          <!-- DropDown -->
          <div v-if="item.href == '#'">
            <dropdown :name="item.name"/>
          </div>
          <!-- Not Dropdown -->
          <div v-else>
            {{ item.name }}
          </div>
        </router-link>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MenuIcon, XIcon } from '@heroicons/vue/outline'
import Dropdown from '../components/Dropdown.vue'

const navigation = [
  { name: 'About us', href: '#', current: false },
  { name: 'Exclusives', href: '#', current: false },
  { name: 'Furniture', href: '#', current: false },
  { name: 'Wall Murals', href: '#', current: false },
  { name: 'Contact us', href: '/contact-us', current: false },
]

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MenuIcon,
    XIcon,
    Dropdown,
  },
  setup() {
    return {
      navigation,
    }
  },
}
</script>