<template>
  <div class="bg-black min-h-screen flex flex-col justify-between">
    <Navbar class="z-50"/>
    <div class="flex-1"><router-view/></div>
    <Footer />
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'

export default {
  components: {
    Navbar,
    Footer
  }
}
</script>

