<template>
  <div class="home h-100 grid justify-items-center">
    <carousel />
    <div class="text-white pt-12 text-center items-center">
      <h1>FINEST BESPOKE INTERIORS</h1>
    </div>
  </div>
</template>

<script>
import carousel from '../components/Carousel.vue';

export default {
  name: 'Home',
  components: {
    carousel,
  }
}
</script>
